import IconKMB from '@/components/icons/Lob/KMB.vue'
import IconKMOB from '@/components/icons/Lob/KMOB.vue'
import IconUC from '@/components/icons/Lob/UC.vue'
import IconWG from '@/components/icons/Lob/WG.vue'
import { LobId } from '~/type/lob'

export default function useLobIcons() {
  const icons = [
    {
      lob_id: LobId.KMB_ID,
      lob_icon: shallowRef(IconKMB),
    },
    {
      lob_id: LobId.KMOB_ID,
      lob_icon: shallowRef(IconKMOB),
    },
    {
      lob_id: LobId.UC_ID,
      lob_icon: shallowRef(IconUC),
    },
    {
      lob_id: LobId.WG_ID,
      lob_icon: shallowRef(IconWG),
    },
  ]

  return icons
}
